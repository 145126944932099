<template>
    <div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4">

       <AddSourceModal v-show="addsource" :closeAddSourcePanel="closeAddSourcePanel"  />
       <UpdateSourceModal v-if="updatesource" :closeUpdateSourcePanel="closeUpdateSourcePanel" :sourceId="sourceId"  @closeSrcWithUpdate="closeSourceWithUpdate" />
        

        <div @click="openAddSourcePanel()" class="flex sm:flex-row flex-col w-full justify-end items-center"> 
            <button class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">Ajouter une source</button>
        </div>


        <div class="flex flex-col h-full w-full" :class="isLoading ? 'hidden' : 'block'">
            <div class="">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste
                        des sources</p>
                    
                </div>
                <div></div>
            </div>

            <div class="brdr rounded-lg py-6 h-full">
                <div class="mx-4 mb-3">
                    

                    <div>
                        <div v-if="sources.length == 0" class="w-full sm:mt-6">
                            <el-result icon="info" title="Aucune source"
                                subTitle="Il n'existe aucune source ! ">
                            </el-result>
                        </div>
                        <div v-else>
                            <div class="w-full rounded-lg bg-white">
                                <div v-for="source in sources" :key="source.value"
                                    class="flex justify-between items-center border rounded-md p-2 my-1 mx-8 transition duration-500 transform-gpu hover:scale-105 hover:bg-red-50 hover:border-red-400">
                                    <div>
                                        {{ source.label }}
                                    </div>

                                    <div class="flex sm:flex-row flex-col justify-center items-center">
                                        <div class="flex item-center justify-center">
                                            <div @click="(updatesource = true), (sourceId = source.value)"
                                                class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                        </div>
                    </div>

                </div>
            </div>




        </div>

        <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
            <!--<div class="fulfilling-square-spinner">
                <div class="spinner-inner"></div>
            </div>-->
            <div class="loading">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>

    </div>
</template>

<script>
import AddSourceModal from './AddSourceModal.vue'
import UpdateSourceModal from './UpdateSourceModal.vue'
import { ElMessage } from 'element-plus'
import axiosIns from '../../../plugins/axios'
export default {
    components: {
       AddSourceModal,
       UpdateSourceModal
    },

    data() {
        return {
            isLoading: false,
            updateModule: false,

            addsource:false,
            updatesource:false,

            sources: [],
            sourceId: null,
        }
    },

    mounted() {
        axiosIns.get("/source")
            .then(({ data }) => {
                var mod = data.sources;

                let temps = []
                if (data.sources.length != undefined) {
                    data.sources.forEach(element => {

                        const item = {
                            label: element.title,
                            value: element.id
                        }
                        temps.push(item);
                    });
                } else {
                    const item = {
                        label: mod[Object.keys(mod)[0]].title,
                        value: mod[Object.keys(mod)[0]].id
                    }
                    temps.push(item);
                }

                this.sources = temps;
                this.isLoading = false;

            })
            .catch(() => {
                this.isLoading = false;
            });
    },

    methods: {

        closeAddSourcePanel() {
            this.addsource = false
        },
        openAddSourcePanel() {
            this.addsource = true
        },
        closeUpdateSourcePanel() {
            this.updatesource = false
        },
        closeSourceWithUpdate(title) {
            console.log("EMITTED");
            this.sources.forEach(element => {
                if (element.value === this.sourceId) {
                    element.label = title;
                }
            });
        },
        
    },

    computed: {
        isToken() {
            return this.$store.getters.get_token;
        },
    },
    setup() {

        const succesUpdate = () => {
            ElMessage({
                message: 'Les données ont été modifiées avec succès.',
                type: 'success',
            })
        }
        const succesDelete = () => {
            ElMessage({
                message: 'Les données ont supprimées avec succès.',
            })
        }
        const errorm = () => {
            ElMessage.error('Une erreur est survenue !')
        }
        return {
            succesUpdate,
            succesDelete,
            errorm
        }
    },
}
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
    box-sizing: border-box;
}

.fulfilling-square-spinner {
    height: 100px;
    width: 100px;
    position: relative;
    border: 4px solid #f87171;
    animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
    vertical-align: top;
    display: inline-block;
    background-color: #f87171;
    width: 100%;
    opacity: 1;
    animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fulfilling-square-spinner-inner-animation {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}</style>