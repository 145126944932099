<template>
    <div>
        <div class="fixed inset-0 z-30 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
        <div class="z-40 inset-0 fixed flex justify-center items-center">
            <div
                class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl">
                <div
                    class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
                    <p class="font-semibold text-sm text-gray-800">Modifier une source</p>
                    <svg @click="closeUpdateSourcePanel" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                        </path>
                    </svg>
                </div>
                <div class="flex flex-col px-6 py-5 bg-gray-50">

                    <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
                        <!--<div class="fulfilling-square-spinner-show">
                            <div class="spinner-inner-show"></div>
                        </div>-->
                        <div class="loading">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </div>


                    <div :class="isLoading ? 'hidden' : 'block'"
                        class=" flex flex-col sm:flex-row items-center text-center text-sm pb-4 pt-2 sm:space-x-5">
                        <div class="w-full">

                            <div class="flex sm:flex-row flex-col items-center py-2">
                                <div class="flex flex-col sm:w-1/2 w-full sm:mx-2">
                                    <div class="mb-1">
                                        <p class="font-semibold text-sm text-gray-800 text-left ml-2">Nom de la source</p>
                                    </div>
                                    <el-input v-model="sourcename" placeholder="Nom de la source" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div
                    class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg ">
                    <button @click="closeUpdateSourcePanel" class="font-semibold text-gray-600 text-sm">
                        Annuler
                    </button>
                    <button @click="confirmeSource()" class=" text-sm px-4 text-red-400 font-semibold bg-white rounded">
                        Confirmer
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axiosIns from '../../../plugins/axios';

export default {
    data() {
        return {
            isLoading: false,
            sourcename: null,
        }
    },

    props: {
        closeUpdateSourcePanel: Function,
        sourceId: Number
    },
    mounted() {
        this.isLoading = true;
        axiosIns
            .get("/source/" + this.sourceId)
            .then(({ data }) => {
                this.sourcename = data.message.title;
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
            });
    },

    methods: {
        confirmeSource() {
            this.isLoading = true;
            axiosIns.put("/source/"+ this.sourceId,
                {
                    title: this.sourcename
                }
            )
                .then(() => {
                    
                    this.$emit("closeSrcWithUpdate", this.sourcename);
                    this.closeUpdateSourcePanel();
                    this.sourcename = "";
                    this.isLoading = false;
                })
                .catch((e) => {
                    this.isLoading = false;
                });
        },
    },

    computed: {
        isToken() {
            return this.$store.getters.get_token;
        }
    },

}
</script>

<style>
.fulfilling-square-spinner-show,
.fulfilling-square-spinner-show * {
    box-sizing: border-box;
}

.fulfilling-square-spinner-show {
    height: 70px;
    width: 70px;
    position: relative;
    border: 4px solid #f87171;
    ;
    animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner-show .spinner-inner-show {
    vertical-align: top;
    display: inline-block;
    background-color: #f87171;
    width: 100%;
    opacity: 1;
    animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fulfilling-square-spinner-inner-animation {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}
</style>